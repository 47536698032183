import React from "react";
import PropTypes from "prop-types";

import { translate, tranStr } from "../../utils/translation";

import ProjectRow from "./BenchmarkingDisplayTableProjectRow";
import TotalRow from "./BenchmarkingDisplayTableTotalRow";

import "./BenchmarkingDisplayTable.css";

/// props validation | SQ(javascript:S6774)
BenchmarkingDisplayTable.propTypes = {
  skipHighlight: PropTypes.bool,
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
  isEscalated: PropTypes.bool,
  setIsEscalated: PropTypes.func,
  unitOfMeasure: PropTypes.string,
  country: PropTypes.string,
  currencySymbol: PropTypes.string,
  cbs: PropTypes.object,
  localRegionFactor: PropTypes.bool,
  caller: PropTypes.string,
  isGlobalBenchmarking: PropTypes.bool,
};
///
export default function BenchmarkingDisplayTable(props) {
  const { skipHighlight } = props;
  const { thisBenchmark, benchmarks } = props;
  const { isEscalated, setIsEscalated } = props;
  const { unitOfMeasure } = props;
  const { country, localRegionFactor, caller } = props;
  const { currencySymbol } = props;
  const { cbs } = props;
  const { isGlobalBenchmarking } = props;

  const baseHeading = isEscalated ? "bdt-cost-off" : "bdt-cost-on";
  const futureHeading = isEscalated ? "bdt-cost-on" : "bdt-cost-off";

  return (
    <div className="benchmarking-table-box">
      <div className="benchmarking-display-table">
        <div className="benchmarking-table-head">
          <div className="bdt-project-name">
            <b>{translate("Project Name")}</b>
          </div>
          <div className="bdt-base-date">
            <b>{translate("Base Date")}</b>
          </div>
          <div className="bdt-lra-unit">
            <b>{translate("Local Region Area Unit")}</b>
          </div>
          <div className="bdt-gia">
            <b>{translate("GIA per m")}<sup>2</sup></b>
          </div>
          <div className="bdt-gfa">
            <b>
                 {translate("Local Region Area")}
            </b>
          </div>
          <div
            className={baseHeading}
            onClick={() => {
              setIsEscalated(false);
            }}
          >
            <b>
              {(localRegionFactor && caller !== "COST_PLANNING"
                ? tranStr("Cost/Local Region Area ")
                : tranStr("Construction Cost") + "/" + unitOfMeasure) +
                "@" +
                " " +
                tranStr("Estimate Date")}
            </b>
          </div>
          <div
            className={futureHeading}
            onClick={() => {
              setIsEscalated(true);
            }}
          >
            <b>
              {(localRegionFactor && caller !== "COST_PLANNING"
                ? tranStr("Cost/Local Region Area ")
                : tranStr("Construction Cost") + "/" + unitOfMeasure) +
                " " +
                tranStr("Escalated")}
            </b>
          </div>
          <div className="bdt-procurement">
            <b>{translate("Procurement Method")}</b>
          </div>
          <div className="bdt-type">
            <b>{translate("Type")}</b>
          </div>
          <div className="bdt-quality">
            <b>{translate("Quality")}</b>
          </div>
          <div className="bdt-sector">
            <b>{translate("Sector")}</b>
          </div>
        </div>
        <div>
          <ProjectRow
            isProject={!skipHighlight}
            benchmark={thisBenchmark}
            unitOfMeasure={unitOfMeasure}
            country={country}
            currencySymbol={currencySymbol}
            cbs={cbs}
            isGlobalBenchmarking={isGlobalBenchmarking}
          />
          {benchmarks.map((b, i) => {
            return (
              <ProjectRow
                isProject={false}
                key={i}
                benchmark={b}
                unitOfMeasure={unitOfMeasure}
                country={country}
                currencySymbol={currencySymbol}
                cbs={cbs}
                isGlobalBenchmarking={isGlobalBenchmarking}
              />
            );
          })}
          <TotalRow benchmarks={benchmarks} currencySymbol={currencySymbol} />
        </div>
      </div>
    </div>
  );
}
